:root {
  --vivanta-purple: #694affff;
  --vivanta-blue: #3343d6ff;
  --vivanta-navy: #1a1b54ff;
  --vivanta-violet: #b050e8ff;
  --vivanta-hot-pink: #fb6282ff;
  --vivanta-sienna: #f6956bff;
  --vivanta-cian: #298bfeff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.color-vivanta-purple {
  color: var(--vivanta-purple);
}

.color-vivanta-white {
  color: white !important;
}

.caption {
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
}

.nav-logo {
  height: 1rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
